import "./skills.scss";
import TagCloud from "react-tag-cloud";
import { Component } from "react";

export default class Skills extends Component {
  componentDidMount() {
    setInterval(() => {
      this.forceUpdate();
    }, 3000);
  }
  render() {
    return (
      <div className="skills" id="skills">
        <div className="skillSet">
          <div className="connectHead">Skills ,<br/> Work Experience</div>
          <div className="skillSec">
            <p>
              I’m a full stack web developer with over 3+ years of experience. I have worked with companies to create
              high performance & rich interactive websites that work across all platforms & devices.
            </p>
            <p>
              I create successful responsive websites that are fast, easy to use, and built with best practices. The
              main area of my expertise is front-end development with various frameworks such as Angular, React and also
              hadncoding with HTML, CSS, JS, building small and medium web apps, custom plugins, features, animations,
              and coding interactive layouts.
            </p>
            <p>I also have full-stack developer experience with Node JS.</p>
          </div>
        </div>
        <div className="skillCanvas">
          <div className="tagCloudOuter">
            <div className="tagCloudInner">
              <TagCloud className="tagCloud"
              style={{
                fontFamily: 'Gideon',
                fontSize: 36,padding:12}}>
                <div>HTML</div>
                <div>CSS</div>
                <div>JS</div>
                <div>Angular</div>
                <div>ReactJS</div>
                <div>Sass</div>
                <div>JSON</div>
                <div>GIT</div>
                <div>Gulp</div>
                <div>NPM</div>
                <div>Bootstrap</div>
                <div>jQuery</div>
                <div>NodeJS</div>
                <div>ES5/ES6</div>
              </TagCloud>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
