import "./projects.scss";

export default function Projects() {

  return (
    <div className="projects" id="projects">
      <div className="connect">
        <div className="connectHead">My Projects</div>
        <div className="projectRows">
        <div className="projectRow1">
        <a href="https://appmemory.netlify.app/" target="_blank" rel="noreferrer">
        <div className="project1">Memories
        <div className="projectSummary">
            A full stack project where any user can store their memories. It is a full stack project with ReactJS as frontend and NodeJS as backend.
        </div>
        <div className="visit">Visit Website</div>
        </div>
        </a>
        <a href="https://appcounts.netlify.app/" target="_blank" rel="noreferrer">
        <div className="project2">Counter
        <div className="projectSummary">
            Want to play with a counter value, this website will let you perform various functions with a simple counter. Primarily made with ReactJS.
        </div>
        <div className="visit">Visit Website</div>
        </div>
        </a>
        </div>
        <div className="projectRow2">
        <a href="https://appcocktail.netlify.app/" target="_blank" rel="noreferrer">
        <div className="project3">Cocktail
        <div className="projectSummary">
            Want to know more about your cocktail, visit my cocktail website. This is primarily made with Angular.
        </div>
        <div className="visit">Visit Website</div>
        </div>
        </a>
        <a href="https://appspacex.netlify.app/" target="_blank" rel="noreferrer">
        <div className="project4">SpaceX
        <div className="projectSummary">
            When were SpaceX shuttles launched, visit me to know all about SpaceX shuttles. I work on Angular framework.
        </div>
        <div className="visit">Visit Website</div>
        </div>
        </a>
        </div>
        </div>
        <div className="upcoming">
            ...... many more coming
        </div>
      </div>
     
    </div>
  );
}
