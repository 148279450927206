import "./intro.scss";
import { FaArrowDown } from "react-icons/fa";

export default function Intro() {
  
  return (
    <div className="intro" id="intro">
      <div style={{position:'relative'}}>
      <div>
        <div className="introDesc">H</div>
        <div className="introDesc">i</div>
        <div className="introDesc">,</div>
        <br />
        <div className="introDesc">I</div>
        <div className="introDesc">'</div>
        <div className="introDesc">m</div>
        <div className="introDesc introMarLeft">
          D
        </div>
        <div className="introDesc">h</div>
        <div className="introDesc">r</div>
        <div className="introDesc">u</div>
        <div className="introDesc">v</div>
        <div className="introDesc">,</div>
        <br />
        <div className="introDesc">W</div>
        <div className="introDesc">e</div>
        <div className="introDesc">b</div>
        <div className="introDesc introMarLeft">
          D
        </div>
        <div className="introDesc">e</div>
        <div className="introDesc">v</div>
        <div className="introDesc">e</div>
        <div className="introDesc">l</div>
        <div className="introDesc">o</div>
        <div className="introDesc">p</div>
        <div className="introDesc">e</div>
        <div className="introDesc">r</div>
      </div>
      </div>
      <div className="bottomDesc">
        <div className="headDesc">UI Developer / Photographer</div>
      </div>
      <div className="scrollDown">
        <span>Scroll Down</span>
        <FaArrowDown />
      </div>
    </div>
  );
}
