import "./contact.scss";
import { FaEnvelope, FaPhoneAlt, FaMapMarkedAlt } from "react-icons/fa";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const formRef = useRef();
  const [done, setDone] = useState(false);
  const [loader, setLoader] = useState(false);

  const sendMsg = (e) => {
    e.preventDefault();
    setLoader(true);
    console.log(formRef.current);
    emailjs.sendForm("service_5ru96a9", "template_txmk2uh", formRef.current, "user_FSXKBPAcd39qy50Chwj1g").then(
      (result) => {
        setDone(true);
        setLoader(false);
        setTimeout(() => {
          setDone(false);
          clearTimeout();
        }, 4000);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div className="contact" id="contact">
      <div className="connectMain">
        <div className="connectHead">Lets Connect</div>
        <div className="connectIcons">
          <FaPhoneAlt />
          <a href="tel:+91-9582559254">+91-9582559254</a>
        </div>
        <div className="connectIcons">
          <FaEnvelope />
          <a href="mailto:dhruvs4w@gmail.com">dhruvs4w@gmail.com</a>
        </div>
        <div className="connectIcons">
          <FaMapMarkedAlt />
          <div>Faridabad, Haryana, 121002</div>
        </div>
      </div>
      <div className="mailForm">
        <form style={{ width: "100%" }} ref={formRef} onSubmit={sendMsg}>
          <div className="nameMail">
            <input type="text" placeholder="Name" name="user_name" className="contactInput nameIP" />
            <label className="ipLabel"></label>
          </div>
          <div className="nameMail">
            <input type="text" placeholder="Email" name="user_email" className="contactInput mailIP" />
            <label className="ipLabel"></label>
          </div>
          <div style={{ position: "relative" }}>
            <input type="text" placeholder="Subject" name="user_subject" className="contactInput subIP" />
            <label className="ipLabel"></label>
          </div>
          <div style={{ position: "relative" }}>
            <textarea name="message" rows="5" placeholder="Message" className="contactInput msgIP"></textarea>
            <label className="ipLabel ipLabelMsg"></label>
            {loader && <div className="loader"></div>}
          </div>
          <button className="sendMsg">Send Message</button>
        </form>
      </div>
      {done && <div className="msgToater">Message Sent Successfully!!!</div>}
    </div>
  );
}
