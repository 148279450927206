import "./sidebar.scss";
import { FaLinkedin, FaGithub, FaWhatsapp } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { useState } from "react";

function Sidebar() {
  const [show, setShow] = useState(true);
  const showSideBar = () => {
    if (window.screen.width <= 1300) {
      if (document.getElementById("toggle").style.display === "block") {
        document.getElementById("toggle").style.display = "none";
        setShow(true);
      } else {
        document.getElementById("toggle").style.display = "block";
        setShow(false);
      }
    }
  };

  return (
    <div>
      {show && (
        <div className="hamIcon" onClick={showSideBar}>
          <GiHamburgerMenu />
        </div>
      )}
      {!show && (
        <div className="hamIcon" onClick={showSideBar}>
          <IoClose />
        </div>
      )}
      <div id="toggle" className="sidebar">
        <div className="logo">
          <img className="imageLogo" src="assets/images/dslogowhite.svg" alt="logo" />
          <div className="logoName">Dhruv</div>
          <div className="logoDesc">Web Developer</div>
        </div>
        <div className="actions">
          <nav className="navigation">
            <a href="#intro" onClick={showSideBar}>
              About
            </a>
            <a href="#skills" onClick={showSideBar}>
              Skills
            </a>
            <a href="#projects" onClick={showSideBar}>
              Projects
            </a>
            {/* <a href="#testimonials">Testimonials</a> */}
            <a href="#contact" onClick={showSideBar}>
              Contact
            </a>
          </nav>
          <div className="socialIcons">
            <a href="https://www.linkedin.com/in/dhruv4sharma/" target="_blank" rel="noreferrer">
              <FaLinkedin />
            </a>
            <a href="https://github.com/dhruv4s" target="_blank" rel="noreferrer">
              <FaGithub />
            </a>
            <a href="https://wa.me/+919582559254" target="_blank" rel="noreferrer">
              <FaWhatsapp />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
