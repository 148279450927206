import "./scss/_app.scss";
import Contact from "./components/contact/Contact";
import Intro from "./components/intro/Intro";
import Projects from "./components/project/Projects";
import Skills from "./components/skills/Skills";
import Sidebar from "./components/sidebar/Sidebar";
import gsap from "gsap";
import { useEffect, useRef } from "react";

function App() {
  const tranRef = useRef();
  const allEl = gsap.utils.selector(tranRef);

  useEffect(() => {
    gsap.timeline().to(allEl("path"), { duration: 0.8, fill: '#FF0000'})
                   .to(allEl("path"), { duration: 0.8, fill: '#939393'})
                   .to(allEl("path"), { duration: 0.6, fill: '#fff'});
  });
  return (
    <div className="app">
      <div className="intialLoadLogo">
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="278.000000pt"
          height="281.000000pt"
          viewBox="0 0 278.000000 281.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,281.000000) scale(0.100000,-0.100000)"
            className="fillSVG"
            fill="#ffffff"
            stroke="none"
            ref={tranRef}
          >
            <path
              d="M1220 2713 c-78 -9 -252 -55 -242 -65 13 -13 1026 -716 1029 -714 1
1 -24 157 -55 346 l-57 345 -95 31 c-138 46 -261 64 -414 62 -72 0 -147 -3
-166 -5z"
            />
            <path
              d="M2061 2183 c37 -219 85 -500 106 -625 21 -126 40 -228 43 -228 3 0
97 129 209 287 l203 286 -33 71 c-82 172 -236 364 -387 479 -58 45 -189 127
-203 127 -3 0 25 -179 62 -397z"
            />
            <path
              d="M2292 1296 c-194 -281 -349 -512 -345 -513 5 -2 157 25 338 60 l330
62 23 65 c53 147 66 232 66 435 0 166 -3 202 -23 285 -13 52 -26 100 -30 106
-3 7 -165 -218 -359 -500z"
            />
            <path
              d="M1952 700 c-332 -65 -606 -120 -608 -123 -4 -4 490 -352 553 -390 16
-10 170 68 261 133 146 103 287 257 380 416 45 76 48 84 30 83 -7 0 -284 -54
-616 -119z"
            />
            <path
              d="M786 831 c2 -9 29 -160 60 -336 30 -176 56 -320 57 -321 1 -1 38 -14
82 -28 181 -57 370 -77 544 -57 88 11 221 38 270 56 8 3 -91 76 -235 173 -137
93 -369 250 -515 349 -146 99 -265 172 -263 164z"
            />
<path
  d="M371 1153 l-194 -286 32 -64 c77 -157 205 -315 346 -430 84 -68 244
-168 253 -158 6 6 -231 1225 -238 1225 -3 0 -92 -129 -199 -287z"
/>
            <path
              d="M815 1989 c-22 -4 -173 -31 -335 -59 -162 -28 -302 -53 -311 -56 -10
-3 -24 -32 -37 -77 -47 -159 -55 -217 -55 -392 -1 -171 9 -243 48 -379 l17
-58 26 39 c42 62 621 894 659 946 19 26 33 47 31 46 -1 -1 -21 -5 -43 -10z"
            />
            <path
              d="M771 2574 c-225 -121 -405 -296 -536 -522 -26 -45 -46 -84 -44 -86 5
-4 1269 230 1275 236 4 5 -584 412 -600 415 -6 2 -49 -18 -95 -43z"
            />
            <path
              d="M1015 1838 c-3 -7 -4 -132 -3 -278 l3 -265 88 -3 87 -3 0 -33 c0 -84
94 -150 215 -151 149 0 237 101 175 203 -25 40 -56 54 -195 87 -121 28 -174
54 -198 95 -24 39 -22 117 3 158 48 78 210 108 315 58 58 -27 78 -47 100 -99
l15 -37 85 0 85 0 -10 25 c-43 114 -127 194 -241 231 -74 23 -516 34 -524 12z"
            />
            
            <path
              d="M1283 1674 c-44 -22 -63 -46 -70 -88 -13 -84 32 -120 197 -156 169
-37 230 -85 230 -179 0 -129 -131 -210 -292 -181 -113 21 -182 76 -195 154
l-6 36 -61 0 -61 0 0 -151 0 -151 240 4 c223 4 244 6 299 27 109 43 187 138
221 268 15 57 21 227 9 257 -5 13 -24 16 -109 16 l-103 0 -10 43 c-13 49 -41
79 -94 102 -48 20 -154 19 -195 -1z"
            />
          </g>
        </svg>
      </div>
      <div className="intialLoad">
        <Sidebar />
        <div className="sections">
          <Intro />
          <Skills />
          <Projects />
          <Contact />
        </div>
      </div>
    </div>
  );
}

export default App;
